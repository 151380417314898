import { Menu, Icon } from 'semantic-ui-react'

function Icons() {
  return (
    <Menu id="social_icons" fixed="bottom" compact className="footerMenu">
      <Menu.Item>
        <a rel="noreferrer nooopener" href="https://www.twitter.com/malgasmtv" target="_blank">
          <Icon name="twitter" size="huge" color="blue" />
        </a>
        <a rel="noreferrer nooopener" href="https://twitch.tv/malgasm" target="_blank">
          <Icon name="twitch" size="huge" color="purple" />
        </a>
      </Menu.Item>
    </Menu>
  )
}

export default Icons;
