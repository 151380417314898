import React, { useState, useEffect } from 'react';
import { Container, Button } from 'semantic-ui-react'
import Particles from "react-tsparticles";
import type { Engine } from "tsparticles-engine";
import { loadFireworksPreset } from "tsparticles-preset-fireworks";
import './Amigoodenough.scss';
import Icons from './Icons';

type TyperSetupFunctionType = () => void

declare global {
  var TyperSetup: TyperSetupFunctionType;
}

function Amigoodenough() {
  const [hasClicked, setClicked] = useState(false);
  const [hasDecided, setDecided] = useState(false);
  const possibleColors = ["#F72119", "#FF9E3D", "#FFF01F", "#1FFF0F", "#04FFF7", "#9500FF", "#FF0EF3"]
  const maxEmitters = 30;

  const particlesOptions = {
    preset: "fireworks"
  };

  async function initParticles(engine: Engine) {
    await loadFireworksPreset(engine);
  }

  function makeEmitters() {
    let emitters = new Array(maxEmitters);
    return emitters.map(makeEmitter);
  }

  function makeEmitter() {
    return {
      life: {
        duration: 1,
        count: 1,
      },
      position: {},
    }
  }

	useEffect(()  => {
    document.body.classList.add('amigoodenough');
    const root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', 'html')
    return () => {
      window.TyperSetup();
      document.body.classList.remove('amigoodenough');
    };
	});

  function pauseAmount() {
    return ((2 + Math.ceil(Math.floor(Math.random()* 10) / 2)) * 1000);
  }

  function onClick() {
    const pauseFor = pauseAmount();
    setClicked(true);

    window.setTimeout(function() {
      setDecided(true);

      window.setTimeout(function() {
        // document.getElementById('social_icons')?.classList?.add('revealed')
      }, 3000);
    }, pauseFor)
  }

  if (hasClicked) {
    if (hasDecided) {
      return (
        <div>
          <h1 className="yes">Yes!😃</h1>
          <Particles options={particlesOptions} init={initParticles} />
          <Icons />
        </div>
      )
    } else {
      return (
        <Container>
          <h1 className="thinking">thinking
            <span className="typer" id="ellipsis" data-words=".........." data-delay="1000" data-loop="false"></span>
            <span className="cursor" data-owner="ellipsis"></span>
          </h1>
        </Container>
      )

    }
  } else {
    return (
      <Container>
        <Button circular className="amigoodenoughButton" onClick={onClick}>
          Am I?
        </Button>
      </Container>
    )
  }
}

export default Amigoodenough;
